// If show_external_values is true, use the sample's external result values
export function useExternalValues(data) {
    data.forEach((item) => {
      if (item.show_external_values) {
        for (let key in item.chemistry_results_external) {
            item.chemistry_results[key] = item.chemistry_results_external[key];
        }
        for (let key in item.quantities_external) {
            item.quantities[key] = item.quantities_external[key];
        }
      }
    });
    return data;
  }