import {
  getBaseApiHelper,
} from '../utils/apiHelpers'

import { useExternalValues } from "../../common/utils/sampleHelpers"

// ACTIONS
const GET_ACCOUNT_ORDERS_REQUEST = 'GET_ACCOUNT_ORDERS_REQUEST'
const GET_ACCOUNT_ORDERS_SUCCESS = 'GET_ACCOUNT_ORDERS_SUCCESS'
const GET_ACCOUNT_ORDERS_FAILURE = 'GET_ACCOUNT_ORDERS_FAILURE'

const getAccountOrdersRequest = () => ({
  type: GET_ACCOUNT_ORDERS_REQUEST
})
const getAccountOrdersSuccess = (orders, accountId) => ({
  type: GET_ACCOUNT_ORDERS_SUCCESS,
  orders,
  accountId
})
const getAccountOrdersFailure = error => ({
  type: GET_ACCOUNT_ORDERS_FAILURE,
  error
})

export const getAccountOrders = (accountId, includeResults) => {
  return dispatch => {
    dispatch(getAccountOrdersRequest())
    return getBaseApiHelper(`/account/${accountId}/orders?include_values=${includeResults}`)
      .then(resp => {
        const orders = resp.data.map(order => {
          return {
            ...order,
            samples: useExternalValues(order.samples)
          }
        })
        dispatch(getAccountOrdersSuccess(orders, accountId))
      }).catch(error => {
        dispatch(getAccountOrdersFailure(error.response))
      })
  }
}

// REDUCER
const initialState = {
  pending: false,
  error: null,
  ordersByAccount: {}
}

export const accountOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNT_ORDERS_REQUEST: {
      return { ...state, pending: true, error: null }
    }
    case GET_ACCOUNT_ORDERS_FAILURE: {
      return { ...state, pending: false, error: action.error }
    }
    case GET_ACCOUNT_ORDERS_SUCCESS: {
      return {
        ...state,
        pending: false,
        error: false,
        ordersByAccount: {
          ...state.ordersByAccount,
          [action.accountId]: action.orders
        }
      }
    }
    default: {
      return { ...state }
    }
  }
}
